import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Menu from '../components/menu';
import Layout from "../components/layout";

const spanStyle = {
  'display': 'block',
  'padding': '20px'
}

export default () => {
	return (
		<Layout>
      <div className="jumbotron">
    		<Link to="/" className="closeX"><span>X</span></Link>
        <Menu localPath="/errata" />
        <h1>Errata</h1>
        <p className="jumbotron-text">
          Um die OpenBooks im Hinblick auf die Beständigkeit Ihres Inhalts mit Printpublikationen vergleichbar zu machen, wird auf dieser Seite der Umgang mit Fehlern offen gelegt.
        </p>
      </div>
      <div className="colorBox">
        <ol style={{ 'marginLeft': '1.5em' }}>
          <li>
            Eine beim Print-On-Demand-Anbieter hinterlegte und veröffentlichte PDF-Datei zählt als Auflage.</li>
          <li>
            Wird eine veränderte Druckdatei hochgeladen, wird diese als Neuauflage gekennzeichnet.
              Beim Print-On-Demand-Anbieter hinterlegte druckfertige PDF-Dateien werden für den
              Fall, dass ein Fehler entdeckt wird, nicht geändert. Fehler können − wie
              bei gedruckten Publikationen üblich − also erst in einer kommenden
              Auflage berücksichtigt werden.
          </li>
          <li>
            Erlange ich Kenntnis von Fehlern in meinen OpenBooks, werden jedoch die auf dieser Domain angebotenen PDF-Dateien (nicht immer dagegen die Vorschaubilder) möglichst zeitnah aktualisiert, d.h. die aktuelle Druckversion und die auf dieser Domain erhältlichen PDF-Dateien weichen in den korrigierten Details voneinander ab.
          </li>
          <li>
            Alle Abweichungen zwischen der Printversion und den auf dieser Domain angeboteten
              PDF-Dateien sind dokumentiert und werden hier veröffentlicht.
          </li>
         </ol>
        <p></p>
        </div>
      <h3>Folgende Fehler wurden korrigiert:</h3>
      <br/>
      <div className="gridBox">
        <div className="gridFraction">Formenlehre der Musik</div>
        <div className="gridFraction">Links (U2) vervollständigt, einige Flüchtigkeitsfehler korrigiert und wegen der fehlerhaften Optimierung durch Adobe (Leerraum bei Ligaturen wie "ft") darüber hinaus das OpenBook mit Ghostscript noch einmal neu kompiliert, den Komplettdownload mit neuem Datum versehen und alles (inklusive Bildern und Einzelseiten) neu hochgeladen. (korrigiert am 16.03.2019). Diese Version entspricht der Druckdatei.
        </div>
        <div className="gridFraction">Harmonielehre #relaoded</div>
        <div className="gridFraction">
          U2: Verweis auf das "Kommentarheft" gestrichen (korrigiert am 05.11.2016)<br/>
          S. 4: "die" vor "Harmonik" ergänzt (korrigiert am 05.11.2016)<br/>
          S. 5: "läss" zu "lässt" verbessert (korrigiert am 05.11.2016)<br/>
          S. 5: "passende" zu "passenden" verbessert (korrigiert am 05.11.2016)<br/>
          S. 5: "dass sie die Oktavregel" zu "dass Sie die Oktavregel" verbessert (korrigiert am 05.11.2016)<br/>
          S. 17: Basstimme zu Bassstimme verbessert (korrigiert am 21.07.2016). Die Korrektur erfolgte 5 Tage nach der Veröffentlichung. Da zu diesem Zeitpunkt die Druckdatei noch nicht bereitgestellt worden ist, konnte der Fehler noch in der Druckdatei berücksichtigt werden.
        </div>
        <div className="gridFraction">Band-Impulse. Heft 3: Pattern und Styles 1 (Rap und Pop)</div>
        <div className="gridFraction">
          Vorwort (S. 5): Der Name von Marcel Korazija (Vorwort) war falsch geschrieben (korrigiert am 17.12.2015).<br/>
          Alle Seitenzählungen um 2 zu hoch, Paginierung korrigiert (korrigiert am 17.12.2015).<br/>
          Inhaltsverzeichnis mit neuen Seitenzahlen korrigiert (korrigiert am 17.12.2015).<br/>
          Urheberrechtsvermerk (U2) in der Schriftgröße angepasst (korrigiert am 17.12.2015).<br/>
          U4: blaue Haarlinie am MSK-Logo entfernt (korrigiert am 17.12.2015).
        </div>
        <div className="gridFraction">Band-Impulse. Heft 1: That Image</div>
        <div className="gridFraction">
          S. 10: In der Gitarrenstimme mit vereinfachten Powerchors muss antelle der Quinte d-a die Quinte h-fis gespielt werden. (korrigiert am 11.06.2015, wenige Stunden nach der Veröffentlichung).<br/>
          S. 12: Fingersatz der linken Hand verbessert (korrigiert am 16.06.2015).<br/>
          U4: Abbildungen zur Bandklasse ergänzt (korrigiert am 16.06.2015).<br/>
          S. 14: Kommentar zum Aufführungsvorschlag ergänzt (korrigiert am 17.06.2015).
        </div>
        <div className="gridFraction">Harmonielehre. Harmonie und Form für Kompositionen in Dur</div>
        <div className="gridFraction">
          S. 11 : Fehlerhafte Abkürzung (»Han-d‹) entfernt (korrigiert am 01.08.2015).
        </div>
        <div className="gridFraction">Ludwig v. Beethoven. Die fünfte Sinfonie</div>
        <div className="gridFraction">
          S. 4 (Unterrichtsheft): Bei der Abbildung "Ludwig van Beethoven mit 13 Jahren" handelt es sich laut freundlicher Auskunft von Friederike Grigat (Leitung Bibliothek und Digitales Beethoven-Haus) nicht um Beethoven, sondern um ein anonymes Jungenbildnis, das sich in der Musikabteilung des Kunsthistorischen Museums in Wien befindet (korrigiert am 02.04.2014).
        </div>
        <div className="gridFraction">Lamentobass. Musik aus vier Jahrhunderten</div>
        <div className="gridFraction">
          S. 27 (Unterrichtsheft): Vorzeichen (fis) fehlt in den Notenbeispielen (korrigiert am 15.07.2014).
        </div>
        <div className="gridFraction">Praktische Harmonielehre: Sequenzen (für Kinder)</div>
        <div className="gridFraction">
          S. 9 (Unterrichtsheft): Übersichtstabelle, zweite Reihe, dritte Spalte, Wortwiederholung ("Als als") entfernt (korrigiert am 27.01.2013).
        </div>
        <div className="gridFraction">Johann Sebastian Bach. Ein Superstar gestern und heute</div>
        <div className="gridFraction">
          <div>
          S. 9 (Unterrichtsheft): Die Taktangabe im "Diagramm der mustergültigen Fuge" (c-Moll-Fuge aus BWV 847) ist ab der dritten Ziffer nach links verrutscht. (korrigiert am 28.03.2012).<br/>
          S. 27 (Unterrichtsheft): Die Zahlen in der Abbildung zur Intervallbestimmung sind verrutscht und stehen nicht über den gestrichelten Linien (korrigiert am 22.10.2012).<br/>
          S. 4 (Kommentarheft): Der Musiklehrer (Oberstudienrat am Ostendorfer-Gymnasium), der dieses Projekt mit einer Aufnahme seiner Klasse so freundlich unterstützt hat, heißt nicht Armin "Schidlow" sondern Armin Schidlo. Ich bitte ganz herzlich um Verzeihung (korrigiert am 22.10.2012).<br/>
          S. 9 (Unterrichtsheft): Dass nicht nur die Taktangaben (s. Korrektur am 28.03.2012), sondern auch sie Beschriftung des ersten Diagramms (c-Moll-Fuge aus BWV 847) verrutscht ist, wurde leider erst jetzt bemerkt (korrigiert am 22.10.2012).<br/>
          S. 9 (Unterrichtsheft): Die Definition zur "Exposition" im gelben Kasten wurde sprachlich geglättet. (korrigiert am 22.10.2012).<br/>
          S. 9 (Unterrichtsheft): Rechtschreibfehler in der Arbeitsaufgabe 1 "Kennzeichnet" wurde durch "Kennzeichnen" ersetzt (korrigiert am 22.10.2012).<br/> S. 11 (Kommentarheft): Notenbeispiel "2. Thema", T. 24 und 26 jeweils untere Nebennote <span class="italic">f</span> zu <span class="italic">fis</span> verbessert (korrigiert am 04.01.2013).
          </div>
        </div>        
        <div className="gridFraction">Themenbogen Intervalle</div>
        <div className="gridFraction">
          Auf der ersten Seite unter der Überschrift "Größe der Intervalle" muss es "bezeichnet" statt "bezeichnete" heißen (korrigiert am 31.10.2012).<br/>
          Zweite Seite, vorletzter Absatz: "eine eine Frage", Wortwiederholung terminiert (korrigiert am 31.10.2012).<br/>
          Text im gelben Kreis rechts unten modifiziert (korrigiert am 31.10.2012).
        </div>
      </div>
      <Helmet>
        <title>OER | Ulrich Kaiser - Errata</title>
        <meta name="description" content="Hier finden Sie eine Übersicht der mir bekannten Irrtümer, die sich in die publizierten OpenBooks (OER) von Ulrich Kaiser eingeschlichen haben." />
        <meta name="keywords" content="Errata, Irrtümer, OER, Open Educational Resources, OpenBooks" />
        <meta name="copyright" content="Ulrich Kaiser, 2011" />
      </Helmet>
    </Layout>
  )
}