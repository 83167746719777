import React from "react";
import { Link } from "gatsby";

const menu = ['Home', 'Warum?', 'Extras', 'Feedback', 'News', 'Print', 'Errata', 'Roadmap', 'Impressum', 'CC0 Musik'];

export default ({ localPath }) => { 
  const menuTags = menu.map((item, index) => {
    let url = item === 'Home' ? '/' : '/'.concat(item.toLowerCase().replace(/[?\s]/g, ''));
    if (url === '/cc0musik') {
      url = "https://cc0.oer-musik.de";
    }
    const link = <Link to={url} className="bnItem">{item}</Link>
    return (
      <React.Fragment>
        {url !== localPath ? link : <span style={{ 'fontWeight': 'bold' }} className="blau4">{menu[index]}</span>}
        {index !== menu.length -1 ? <span> | </span> : ''}
      </React.Fragment>
    )
  })

  return <div>{menuTags}</div>
}